/*
 * Roxy: Bootstrap template by GettTemplates.com
 * https://gettemplates.co/roxy
 */
html,
body {
  width: 100%;
  overflow-x: hidden;
}

@import url('https://fonts.googleapis.com/css?family=Lato:300,400|Work+Sans:300,400,700');

body {
  font-family: 'Lato', sans-serif;
  font-weight: 300;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: 'Work Sans', sans-serif;
  margin-top: 0;
}
a {
  color: F26024#F26024;
  transition: all 0.3s ease-in-out;
}

a:focus,
button:focus {
  outline: none;
}

.btn:hover,
a:hover {
  text-decoration: none;
  color: #333399;
}

img {
  max-width: 100%;
}

.bg-fixed {
  background-size: cover;
}

.bg-grey {
  background-color: #f7f8f9;
}

.fs-40 {
  font-size: 40px;
}

section {
  position: relative;
}

.section-content {
  padding-top: 80px;
  padding-bottom: 80px;
}

.title-wrap,
.section-title {
  text-align: center;
}

.shadow {
  box-shadow: 0 10px 30px 0 rgba(67, 80, 98, 0.24),
    0 2px 4px 0 rgba(67, 80, 98, 0.1) !important;
}

#header-navbar {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 11;
  transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

.navbar-brand {
  font-size: 40px;
  color: #000;
}

.navbar-nav .nav-link {
  color: #fff;
  transition: 0.3s ease;
}

.navbar-nav:hover li a {
  opacity: 0.5;
}
.navbar-nav:hover li a:hover {
  opacity: 1;
}

.navbar-toggler {
  font-size: 2rem;
  color: #fff;
}

.jumbotron {
  color: #fff;
  padding-top: 80px;
  padding-bottom: 80px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  position: relative;
  border-radius: 0;
  margin-bottom: 0;
  overflow: hidden;
  min-height: 100vh;
}
@media (max-width: 767px) and (orientation: landscape) {
  .jumbotron {
    min-height: 500px;
  }
}
.jumbotron .container {
  position: relative;
  z-index: 1;
}
.jumbotron:before {
  position: absolute;
  display: block;
  content: '';
  opacity: 0.8;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #f26024;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #333399, #f26024);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #333399, #f26024);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.jumbotron h1 {
  line-height: 0.9;
  font-weight: bold;
  display: inline-block;
  border: 15px solid #fff;
  padding: 30px;
  font-size: 9rem;
  opacity: 0;
  animation: 1s fadeInFromTop cubic-bezier(0.785, 0.135, 0.15, 0.86) 1s forwards;
  animation-delay: 0.8s;
}

.jumbotron-single h1 {
  font-size: 3rem !important;
}

#whiteBG {
  width: 100%;
  opacity: 0.3;
  min-height: 850px;
}

#carosel {
  height: 620px;
}

#user-testimonial {
  color: #000;
  margin-bottom: 100px;
}

#testi-text {
  margin-bottom: 250px;
}

#test-items {
  color: #000;
}

.overlay {
  color: #fff;
  overflow: hidden;
}
.overlay:before {
  position: absolute;
  display: block;
  content: '';
  opacity: 0.9;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #f26024;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #333399, #f26024);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #333399, #f26024);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.overlay.bg-white {
  color: #000;
}
.overlay.bg-white:before {
  background: #fff;
  opacity: 0.7;
}

.text-white {
  color: #fff;
}

/*==============================
=            BUTTON            =
==============================*/
.btn {
  font-size: 14px;
  font-weight: 600;
  border-radius: 30px;
  padding: 9px 25px;
  text-transform: capitalize;
  transition: all 0.3s ease 0s;
}

.btn-primary {
  display: inline-block;
  background-color: #f26024;
  border-color: #f26024;
  color: #fff;
}
.btn-primary:hover {
  background-color: #333399;
  border-color: #333399;
  color: #fff;
}

.btn-outline-primary {
  color: #fff;
  background-color: transparent;
  background-image: none;
  border-color: #fff;
}
.btn-outline-primary:hover {
  background-color: #333399;
  border-color: #333399;
  color: #fff;
}

.btn-shadow {
  box-shadow: -5px 8px 20px 0px rgba(229, 16, 2, 0.15);
}

.btn-icon {
  color: #000;
}

.btn-link {
  color: #f26024;
}

/*=====  End of BUTTON  ======*/
/*==========================================
=            FORM RECTANGLE ETC            =
==========================================*/
.rectangle-1 {
  width: 2000px;
  height: 400px;
  background: #8e2de2;
  background: -webkit-linear-gradient(to right, #4a00e0, #8e2de2);
  background: linear-gradient(to right, #4a00e0, #8e2de2);
  transform-origin: 50% 150% 0;
  position: absolute;
  left: 0;
  z-index: 0;
  transform: scale(1) rotate(-10deg);
}

.rectangle-2 {
  width: 2000px;
  height: 400px;
  background: #8e2de2;
  background: -webkit-linear-gradient(to right, #4a00e0, #8e2de2);
  background: linear-gradient(to right, #4a00e0, #8e2de2);
  transform-origin: 65% 100% 0;
  position: absolute;
  right: 0;
  z-index: 0;
  transform: scale(1) rotate(50deg);
}

@keyframes fade-in-right {
  from {
    opacity: 0;
    transform: translateX(-100vw) rotate(-15deg);
  }
  to {
    opacity: 1;
    transform: translateX(0) rotate(-15deg);
  }
}

@keyframes grow1 {
  from {
    opacity: 0;
    transform: scale(2) rotate(-10deg);
  }
  to {
    opacity: 1;
    transform: scale(1) rotate(-10deg);
  }
}

@keyframes grow2 {
  from {
    opacity: 0;
    transform: scale(2) rotate(50deg);
  }
  to {
    opacity: 1;
    transform: scale(1) rotate(50deg);
  }
}

@keyframes fadeInFromTop {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.rectangle-1 {
  opacity: 0;
  animation: grow1 ease 1s forwards;
  box-shadow: 0px 20px 30px 0px rgba(9, 21, 54, 0.25) !important;
}

.rectangle-2 {
  opacity: 0;
  animation: grow2 ease 1s forwards;
  box-shadow: 0px 20px 30px 0px rgba(9, 21, 54, 0.25) !important;
}

.rectangle-transparent-1 {
  width: 500px;
  height: 500px;
  border: 15px solid rgba(255, 255, 255, 0.08);
  position: absolute;
  left: -5%;
  bottom: -10%;
  display: block;
  animation: floating-slow ease-in-out 12s infinite;
}

.rectangle-transparent-2 {
  width: 600px;
  height: 600px;
  border: 15px solid rgba(255, 255, 255, 0.08);
  position: absolute;
  right: -10%;
  top: 5%;
  display: block;
  animation: floating-slow ease-in-out 12s infinite;
}

.circle-1 {
  width: 50px;
  height: 50px;
  border: 2px solid #fff;
  position: absolute;
  display: block;
  border-radius: 50%;
  transform-origin: 50% 50%;
  left: 5%;
  top: 50%;
  animation: fadeInFromTop 0.5s linear forwards, floating ease 4s infinite;
}

.circle-2 {
  width: 70px;
  height: 70px;
  top: 20%;
  left: 83%;
  border: 2px solid #fff;
  position: absolute;
  display: block;
  border-radius: 50%;
  transform-origin: 50% 50%;
  animation: fadeInFromTop 0.5s linear forwards,
    floating ease-in-out 4s infinite;
}

.circle-3 {
  top: 80%;
  right: 25%;
  width: 40px;
  height: 40px;
  border: 2px solid #fff;
  position: absolute;
  display: block;
  border-radius: 50%;
  animation: fadeInFromTop 0.5s linear forwards,
    floating ease-in-out 4s infinite;
}

@keyframes floating {
  0% {
    transform: translate(0%, 0%) rotate(25deg);
  }
  25% {
    transform: translate(5%, 15%) rotate(25deg);
  }
  50% {
    transform: translate(10%, 5%) rotate(25deg);
  }
  75% {
    transform: translate(0%, 15%) rotate(25deg);
  }
  100% {
    transform: translate(0%, 0%) rotate(25deg);
  }
}

@keyframes floating-slow {
  0% {
    transform: translate(0%, 0%) rotate(25deg);
  }
  25% {
    transform: translate(1%, 3%) rotate(25deg);
  }
  50% {
    transform: translate(2%, 1%) rotate(25deg);
  }
  75% {
    transform: translate(0%, 3%) rotate(25deg);
  }
  100% {
    transform: translate(0%, 0%) rotate(25deg);
  }
}

.triangle {
  position: absolute;
}

.triangle-1 {
  right: 0;
  animation: fadeInFromTop 0.5s linear forwards,
    floating ease-in-out 6s infinite;
}
.triangle-1 img {
  height: 50px;
  width: 50px;
  transform: rotate(30deg);
}

.triangle-2 {
  top: 30%;
  left: 20%;
  animation: fadeInFromTop 0.5s linear forwards,
    floating ease-in-out 8s infinite;
}
.triangle-2 img {
  width: 75px;
  height: 75px;
  transform: rotate(15deg);
}

.triangle-3 {
  top: 80%;
  left: 15%;
  animation: fadeInFromTop 0.5s linear forwards,
    floating ease-in-out 10s infinite;
}
.triangle-3 img {
  width: 45px;
  height: 45px;
  transform: rotate(40deg);
}

.triangle-4 {
  top: 60%;
  right: 15%;
  animation: fadeInFromTop 0.5s linear forwards,
    floating ease-in-out 5s infinite;
}
.triangle-4 img {
  width: 45px;
  height: 45px;
  transform: rotate(-40deg);
}

/*=====  End of FORM RECTANGLE ETC  ======*/
/*================================
=            FEATURES            =
================================*/
.feature-item {
  transition: all 0.2s ease-in-out 0s !important;
}
.feature-item:hover {
  transform: translateY(-13px);
}

.progress-bar {
  background-color: #f26024;
}

/*=====  End of FEATURES  ======*/
/*=============================
=            TESTI            =
=============================*/
.testi-img img {
  height: 60px;
  width: 60px !important;
  border-radius: 50%;
  display: inline-block !important;
}

.testi-details {
  text-align: left;
  margin-left: 10px;
}

.testi-text {
  font-weight: 300;
  padding-top: 50px;
  padding-bottom: 50px;
}

.testi-icon {
  color: #f26024;
}

.testi-content {
  margin-top: 80px;
  padding-bottom: 80px;
}

.testi-item {
  margin-left: 15px;
  margin-right: 15px;
}

.owl-dot {
  position: relative;
  display: inline-block;
  margin: 0 5px;
  width: 12px;
  height: 12px;
  cursor: pointer;
}
.owl-dot span {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  outline: none;
  border-radius: 50%;
  background-color: rgba(255, 0, 204, 0.3);
  text-indent: -999em;
  cursor: pointer;
  position: absolute;
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0);
  -webkit-transition: box-shadow 0.3s ease, background-color 0.3s ease;
  transition: box-shadow 0.3s ease, background-color 0.3s ease;
}
.owl-dot.active > span {
  background-color: transparent;
  box-shadow: 0 0 0 2px #f26024;
}

.owl-dots {
  position: absolute;
  bottom: 0;
  display: block;
  text-align: center;
  left: 0;
  right: 0;
  clear: both;
  padding: 0;
  list-style: none;
  cursor: default;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.heading-section {
  position: relative;
}

/*=====  End of TESTI  ======*/
/*=================================
=            PORTFOLIO            =
=================================*/
.filter-button-group {
  margin-bottom: 15px;
}
.filter-button-group a {
  margin: 5px;
  cursor: pointer;
}

.grid-link a {
  margin: 5px;
  font-size: 20px;
  color: #000;
}

.grid-portfolio {
  overflow: hidden;
}

.gutter-sizer {
  width: 2%;
}

.grid-sizer,
.grid-item {
  width: 32%;
}

.grid-item {
  margin-bottom: 20px;
  border-radius: 5px;
  overflow: hidden;
}
.grid-item:hover .grid-info {
  top: 50%;
  opacity: 1;
}

.grid-item-wrapper:before {
  content: '';
  display: block;
  background: rgba(255, 255, 255, 0.7);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: all 0.3s ease;
  opacity: 0;
}

.grid-item-wrapper:hover:before {
  opacity: 1;
}

.grid-info {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: all 0.3s ease;
}

.lightcase-icon-close:before {
  content: '\e870';
  font-family: 'Linearicons-Free';
}

.grid-title {
  text-align: center;
}

/*=====  End of PORTFOLIO  ======*/
/*===============================
=            SideNav            =
===============================*/
.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1002;
  top: 0;
  left: 0;
  background-color: #000;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
  color: #fff;
}

.sidenav-content {
  padding: 8px 32px;
  transform: translateY(20px);
  opacity: 0;
  transition: transform 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86),
    opacity 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

.in .sidenav-content {
  transform: translateY(0);
  opacity: 1;
  transition: transform 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0.5s,
    opacity 0.5s 0.5s;
}

.sidenav a {
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.sidenav a:hover {
  color: #f1f1f1;
}

.sidenav #side-nav-close,
.sidenav #side-search-close {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }
  .sidenav a {
    font-size: 18px;
  }
  .individual-card {
    padding: 10px;
    width: 80%;
  }
}

#side-search .form-control {
  background-color: transparent;
}

#side-search .input-group-text {
  background: transparent;
  color: #fff;
}

/*==============================
=            SKILLS            =
==============================*/
.skill-item {
  width: 100%;
  position: relative;
  padding: 10px 0;
}
.skill-item p {
  font-size: 16px;
  text-transform: uppercase;
  display: inline-block;
  font-weight: 500;
}
.skill-item .text-muted {
  color: #939393;
}

.progress-bar,
.progress {
  transition: all 4s;
}

/*=====  End of SKILLS  ======*/
/*============================
=            BLOG            =
============================*/
.blog-item {
  transition: all 0.3s ease-in-out;
  background-color: #f7f8f9;
  border-radius: 3px;
  overflow: hidden;
}
.blog-item:hover {
  box-shadow: 0 5px 15px -5px #333;
  transform: translateY(-6px);
  -webkit-transform: translateY(-6px);
  -moz-transform: translateY(-6px);
  transform: translateY(-6px);
  box-shadow: 0 7px 10px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 7px 10px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0 7px 10px rgba(0, 0, 0, 0.15);
}

.bg-grey .blog-item {
  background-color: #fff;
}

.blog-item-wrapper {
  margin-bottom: 30px;
}

.blog-img img {
  width: 100%;
  height: 250px;
  object-fit: cover;
}

.blog-title h4 {
  color: #000;
}

.blog-text {
  padding: 15px;
}

.blog-tag {
  color: #000;
  letter-spacing: 1.1px;
  text-transform: uppercase;
}

.blog-meta {
  color: #939393;
}
.blog-meta p {
  display: inline-block;
  font-size: 14px;
}
.blog-meta a {
  color: #939393;
}

.blog-author {
  display: inline-block;
  color: #939393;
}
.blog-author p {
  font-size: 14px;
  margin-bottom: 0;
}

.blog-share-wrapper {
  /* float: right; */
  display: inline-block;
  margin: 0 -5px;
}
.blog-share-wrapper .blog-share {
  padding: 0 5px;
  cursor: pointer;
}

.blog-content img.float-left {
  margin: 15px 15px 15px 0;
}

.blog-content .img.float-right {
  margin: 15px 0 15px 15px;
}

/*=====  End of BLOG  ======*/
/*====================================
=            FORM CONTROL            =
====================================*/
.form-control:focus {
  border-color: #f26024;
  box-shadow: 0 0 0 0.2rem rgba(253, 101, 90, 0.25);
}

.form-control::-webkit-input-placeholder {
  opacity: 0.5;
}

.form-control::-moz-placeholder {
  opacity: 0.5;
}

.form-control:-ms-input-placeholder {
  opacity: 0.5;
}

.form-control::-ms-input-placeholder {
  opacity: 0.5;
}

.form-control::placeholder {
  opacity: 0.5;
}

/*=====  End of FORM CONTROL  ======*/
/*==============================
=            CLIENT            =
==============================*/
.client-slider .owl-nav {
  display: none;
}

.client-slider .client-item {
  padding: 0 40px;
}

/*=====  End of CLIENT  ======*/
/*==================================
=            RESPONSIVE            =
==================================*/
@media (max-width: 992px) {
  .not-on-top #header-navbar {
    position: fixed;
    top: 0;
    height: auto;
    width: 100%;
    background: #f26024;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #333399, #f26024);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #333399, #f26024);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    z-index: 11;
  }
  #header-navbar {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
  .navbar-transparent .navbar-collapse {
    background: #f26024;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #333399, #f26024);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #333399, #f26024);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    padding: 10px;
  }
}

@media (max-width: 767px) {
  .jumbotron h1 {
    font-size: 6rem;
  }
  .grid-item {
    width: 100%;
  }
  .progress {
    height: 30px;
    font-size: 16px;
  }
  .individual-card {
    padding: 10px;
    width: 80%;
  }
}

/*=====  End of RESPONSIVE  ======*/
.co-working-table {
  margin-top: 50px;
}

.co-working-div {
  margin-top: 50px;
  margin-bottom: 50px;
}

.co-working-title {
  margin-top: 50px;
}

.individual-card {
  padding: 60px;
  width: 60%;
}

.card-1 {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  font-size: large;
}

.card-2 {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  text-align: right;
  font-size: large;
}

@media screen and (max-width: 700px) {
  .individual-card {
    padding: 20px;
    width: 85%;
  }
  .program-p {
    padding: 0px 20px 0px 20px;
  }
  .card-1 {
    font-size: small;
  }
  .card-2 {
    font-size: small;
  }
  .about-img {
    flex-direction: column;
  }
  .services {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    margin-top: 50px;
    margin-bottom: 40px;
  }

  .footer-icon {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
}

.about-staff {
  text-align: center;
  margin-bottom: 30px;
  margin-top: 90px;
}

.about-img {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.stack {
  /* text-align: left; */
  /* background-color: red; */
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 30px;
  /* justify-content: flex-end; */
}

.stack-2 {
  text-align: right;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.stack-1 {
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.footer-links {
  display: flex;
  flex-direction: column;
}

.services {
  display: flex;
  justify-content: space-around;
  margin-top: 50px;
  margin-bottom: 40px;
}

.footer-icon {
  display: flex;
  justify-content: space-around;
  margin: 40px 30px 70px 30px;
}

@media screen and (max-width: 500px) {
  .services {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    margin: 50px 30px 40px 30px;
  }

  .footer-icon {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin: 40px 30px 70px 30px;
  }

  .stack-1 {
    padding: 15px;
  }

  .stack-2 {
    padding: 15px;
  }

  .jumbotron h1 {
    margin: 0px;
    padding: 5px;
    font-size: 4rem;
  }
}

@media screen and (min-width: 500px) {
  .footer-icon {
    margin: 40px 30px 70px 30px;
  }
}

.staff {
  border-radius: 5px;
}

.profile {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  background: #f7f8f9;
  padding: 10px;
}

.profile-text {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.nav-item{
  margin: 0px 10px 0px 10px;
  font-weight: 400;
}
